<template>
  <div class="row justify-content-center">
    <div class="col-lg-5 col-md-7">
      <div class="card bg-secondary shadow border-0">
        <div class="card-body px-lg-5 py-lg-5">
          <div class="text-center text-muted mb-4">
            <small>Sign in with credentials</small>
          </div>
          <form role="form" @submit.prevent="handleSubmit()">
            <base-input
                v-model="email"
                addon-left-icon="ni ni-email-83"
                alternative
                class="input-group-alternative mb-3"
                name="Email"
                placeholder="Email"
            >
            </base-input>
            <validation-error :errors="apiValidationErrors.email"/>

            <base-input
                v-model="password"
                addon-left-icon="ni ni-lock-circle-open"
                alternative
                class="input-group-alternative"
                name="Password"
                placeholder="Password"
                type="password"
            >
            </base-input>
            <validation-error :errors="apiValidationErrors.password"/>

            <base-checkbox v-model="rememberMe" class="custom-control-alternative">
              <span class="text-muted">Remember me</span>
            </base-checkbox>
            <div class="text-center">
              <base-button class="my-4" native-type="submit" type="primary"
              >Sign in
              </base-button
              >
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/form-mixin";

export default {
  name: "login",
  components: {
    ValidationError,
  },
  mixins: [formMixin],
  data: () => ({
    email: "",
    password: "",
    rememberMe: false
  }),
  computed: {
    isAuthenticated: function () {
      return this.$store.getters.isAuthenticated();
    },
  },
  methods: {
    async handleSubmit() {
      const user = {
        type: "token",
        email: this.email,
        password: this.password,
        rememberMe: this.rememberMe
      };

      const requestOptions = {
        headers: {
          Accept: "application/vnd.api+json",
          "Content-Type": "application/vnd.api+json",
        },
      };

      try {
        await this.$store.dispatch("login", {user, requestOptions});
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Invalid credentials!",
        });
        this.setApiValidation(error.response.data);
      }
    },
  },
};
</script>
