<template>
  <div>
    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col">
          <div class="card shadow">
            <div class="card-header">
              <div class="row align-items-center">
                <div class="col-10">
                  <h3 class="mb-0 text-dark">
                    {{ title }}
                  </h3>
                </div>
                <div class="col-2 text-right">
                  <base-button size="sm" type="btn-outline" @click="createGallery"><i
                      class="fas fa-lg fa-plus text-dark"></i></base-button>
                </div>
              </div>
            </div>

            <div v-if="tableData && tableData.length > 0" class="card-body">
              <div class="table-responsive">
                <base-table
                    v-if="tableData && tableData.length > 0"
                    :data="tableData"
                    class="table align-items-center table-flush"
                    tbody-classes="list"
                    thead-classes="thead-light"
                >
                  <template slot="columns">
                    <th></th>
                    <th>Дата</th>
                    <th>Название</th>
                    <th></th>
                  </template>

                  <template slot-scope="{ row }">
                    <td>
                      #
                    </td>
                    <td>
                      <span class="name mb-0 text-sm">{{ dateToYMDHis(new Date(row.created_at)) }}</span>
                    </td>
                    <th class="budget" scope="row">
                      <div class="media align-items-center">
                        <div class="media-body">
                          <router-link :to="{name: 'gallery.show', params: {id: row.id}}" class="name mb-0 text-sm">
                            {{ row.name }}
                          </router-link>
                        </div>
                      </div>
                    </th>
                    <td class="text-right">
                      <base-button size="sm" type="btn-outline" @click="editGallery(row.id)"><i
                          class="fas fa-lg fa-edit text-dark"></i></base-button>
                      <base-button size="sm" type="btn-outline" @click="deleteGallery(row.id)"><i
                          class="fas fa-lg fa-trash text-dark"></i></base-button>
                    </td>
                  </template>
                </base-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ConfirmDialogue ref="confirmDialogue"/>
  </div>
</template>

<script>
export default {
  name: 'Galleries',

  data() {
    return {
      tableData: null,
      title: 'Список галерей',
      showModal: false
    };
  },

  async created() {
    this.$store.watch(
        () => this.$store.getters["GALLERIES_LIST"],
        (list) => {
          this.tableData = list
        }
    );
    await this.$store.dispatch("GET_GALLERIES_LIST");
  },

  methods: {
    getLocalesString(localesArray) {

      if (Array.isArray(localesArray) && localesArray.length > 0) {
        let localesString = '';

        localesArray.forEach(locale => {
          localesString = localesString + ' ' + locale;
        });

        return localesString;
      }

      return "";
    },
    dateToYMDHis(date) {
      let d = date.getDate();
      let m = date.getMonth() + 1; //Month from 0 to 11
      let y = date.getFullYear();
      let H = date.getHours();
      let i = date.getMinutes();
      return '' + (d <= 9 ? '0' + d : d) + '-' + (m <= 9 ? '0' + m : m) + '-' + y + ' ' + H + ':' + i;
    },
    editGallery(id) {
      this.$router.push({name: "gallery.show", params: {id: id}});
    },
    createGallery() {
      this.$router.push({name: "gallery.create"});
    },
    deleteGallery(id) {
      this.$store.dispatch("DELETE_GALLERY", {
        id: id
      }).then(() => {
        this.$store.dispatch("GET_GALLERIES_LIST");
        this.$notify({type: 'success', message: 'Галерея успешно удалена'})
      }).catch(() => {
        this.$notify({type: 'danger', message: 'Галерея не удалена'})
      });
    }
  },
};
</script>
