<template>
  <div>
    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col">
          <div class="card shadow">
            <div class="card-header">
              <div class="row align-items-center">
                <div class="col-10">
                  <h3 class="mb-0 text-dark">
                    {{ title }}
                  </h3>
                </div>
                <div v-if="tableData && tableData.length > 0" class="col-2 text-right inline">
                  <button class="btn btn-outline">
                    <download-csv
                        :data="tableData">
                      <i class="fas fa-lg fa-file-download text-dark"></i>
                    </download-csv>
                  </button>

                </div>
              </div>
            </div>

            <div v-if="tableData && tableData.length > 0" class="card-body">
              <div class="table-responsive">
                <base-table
                    v-if="tableData && tableData.length > 0"
                    :data="tableData"
                    class="table align-items-center table-flush"
                    tbody-classes="list"
                    thead-classes="thead-light"
                >
                  <template slot="columns">
                    <th></th>
                    <th>locale</th>
                    <th>email</th>
                    <th></th>
                  </template>

                  <template slot-scope="{ row }">
                    <td>
                      #
                    </td>
                    <td>
                      <span class="name mb-0 text-dark text-sm">{{ row.locale }}</span>
                    </td>
                    <td>
                      <span class="name mb-0 text-dark text-sm">{{ row.email }}</span>
                    </td>
                    <td class="text-right">
                      <base-button size="sm" type="btn-outline" @click="deleteSubscriber(row.email)"><i
                          class="fas fa-lg fa-trash text-dark"></i></base-button>
                    </td>
                  </template>
                </base-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ConfirmDialogue ref="confirmDialogue"/>
  </div>
</template>

<script>


export default {
  data() {
    return {
      tableData: null,
      title: 'Список подписчиков',
      showModal: false
    };
  },

  async created() {
    this.$store.watch(
        () => this.$store.getters["SUBSCRIBERS_LIST"],
        (list) => {
          this.tableData = list
        }
    );
    await this.$store.dispatch("GET_SUBSCRIBERS_LIST");
  },

  methods: {
    exportToCsv() {

    },
    deleteSubscriber(email) {
      this.$store.dispatch("DELETE_SUBSCRIBER", {
        email: email
      }).then(() => {
        this.$store.dispatch("GET_SUBSCRIBERS_LIST");
        this.$notify({type: 'info', message: 'Подписчик успешно удален'})
      }).catch(() => {
        this.$notify({type: 'info', message: 'Подписчик не удален'})
      });
    }
  },
};
</script>