<template>
  <div class="container-fluid mt--7">
    <div v-if="gallery" class="row">
      <div class="col-xl order-xl-1">
        <card shadow type="secondary">
          <template v-slot:header>
            <div class="bg-white border-0">
              <div class="row align-items-center">
                <div class="col-8">
                  <h3 v-if="isCreating" class="mb-0">Создание галереи</h3>
                  <h3 v-else class="mb-0">Редактирование галереи - {{ gallery.name }}</h3>
                </div>
                <div class="col text-right">
                  <button class="btn btn-outline" size="sm" @click="handleSubmit">
                    <i class="fas fa-lg fa-save text-dark"></i>
                  </button>
                </div>
              </div>
            </div>
          </template>
          <h6 class="heading-small text-muted mb-4">Основная информация</h6>
          <div class="pl-lg-4">
            <div class="row">
              <div class="col-lg-6">
                <base-input
                    v-model="gallery.name"
                    input-classes="form-control-alternative"
                    label="Название"
                    placeholder="Название"
                />
              </div>
            </div>
          </div>
          <h6 class="heading-small text-muted mb-4">Изображения</h6>
          <div v-if="gallery.images">
            <div v-for="(image, key) in gallery.images" v-bind:key="'image_' + key"
                 class="mb-4 card card-stats" show-footer-line="true">
              <div class="card-body">
                <div class="row">
                  <div class="col-6">
                    <base-input
                        v-model="gallery.images[key].name"
                        input-classes="form-control-alternative"
                        label="Название"
                        placeholder="Название"
                    />
                    <base-input
                        v-model="gallery.images[key].position"
                        input-classes="form-control-alternative"
                        label="Позиция"
                        placeholder="Позиция"
                        type="number"
                    />
                  </div>
                  <div class="col-6">
                    <UploadImage
                        v-model="gallery.images[key]"
                        textChange="Изменить изображение"
                        textUpload="Загрузить изображение"
                    />
                  </div>
                </div>
              </div>
              <div class="text-right mb-4 mr-4">
                <button class="btn btn-outline" size="sm" @click="removeImage(image)">
                  <i class="fas fa-lg fa-trash text-dark"></i>
                </button>
              </div>
            </div>
          </div>
          <button class="btn btn-outline mt-4" size="sm" @click="addImage"><i
              class="fas fa-lg fa-plus text-dark"></i></button>
        </card>
      </div>
    </div>
  </div>
</template>

<script>

import UploadImage from "../../components/UploadImage";

export default {
  name: "galleryForm",
  components: {
    UploadImage
  },
  computed: {
    gallery: {
      get() {
        return this.$store.getters["GALLERY"];
      },
      set(value) {
        this.$store.commit("SET_GALLERY", value);
      }
    },
    isCreating() {
      return this.$route.params.id === undefined;
    },
  },
  async created() {
    this.$store.watch(
        () => this.$store.getters["GALLERY"],
        (gallery) => {
          this.gallery = gallery
        }
    );
    if (!this.isCreating) {
      await this.$store.dispatch("GET_GALLERY", {id: this.$route.params.id});
    } else {
      this.$store.commit("SET_GALLERY", {
        name: null,
        images: [],
      });
    }
  },
  methods: {
    async handleSubmit() {
      try {
        if (this.isCreating) {
          await this.$store.dispatch("CREATE_GALLERY", this.gallery);
        } else {
          await this.$store.dispatch("UPDATE_GALLERY", this.gallery);
        }
        this.$notify({
          type: "success",
          message: this.isCreating ? 'Галерея успешно создана' : ' Галерея успешно обновлена',
        });
        if (this.isCreating) {
          await this.$router.push({name: "image.show", params: {id: this.image.id}});
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Уупс, что-то пошло не так!",
        });
      }
    },

    async deleteGallery() {
      await this.$store.dispatch("DELETE_IMAGE", {
        id: this.$route.params.id
      }).then(() => {
        this.$notify({type: 'success', message: 'Изображение успешно удалено'})
      }).catch(() => {
        this.$notify({type: 'danger', message: 'Ихображение не удалено'})
      });
    },

    onFileSelected(event, image) {
      image = event.target.files[0];
      image.path = URL.createObjectURL(this.file);
    },

    removeImage(image) {
      this.gallery.images = this.gallery.images.filter(item => item !== image);

    },

    addImage() {
      this.gallery.images.push({
        id: null,
        name: null,
        path: null,
        created_at: null
      });
    }
  }
}
</script>
<style scoped>

.grid-images {
  display: grid;
  grid-auto-flow: column;
}

img.preview {
  width: 200px;
  background-color: white;
  border: 1px solid #DDD;
  padding: 5px;
}

</style>